import React, { useState, useContext } from "react";
import { toast } from "react-toastify";
import { format, parseISO } from "date-fns";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import {
	Button,
	IconButton,
	TableBody,
	TableRow,
	TableCell,
	Table,
	TableHead,
	Paper,
	Chip
} from "@material-ui/core";
import {
	DeleteOutline
} from "@material-ui/icons";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import TableRowSkeleton from "../../components/TableRowSkeleton";

import api from "../../services/api";
import ConfirmationModal from "../../components/ConfirmationModal";
import { i18n } from "../../translate/i18n";
import { APIConnectionsContext} from "../../context/APIConnections/APIConnectionsContext";
import toastError from "../../errors/toastError";

import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../../components/Can";

const useStyles = makeStyles(theme => ({
	mainPaper: {
		flex: 1,
		padding: theme.spacing(1),
		overflowY: "scroll",
		...theme.scrollbarStyles,
	},
	customTableCell: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	tooltip: {
		backgroundColor: "#f5f5f9",
		color: "rgba(0, 0, 0, 0.87)",
		fontSize: theme.typography.pxToRem(14),
		border: "1px solid #dadde9",
		maxWidth: 450,
	},
	tooltipPopper: {
		textAlign: "center",
	},
	buttonProgress: {
		color: green[500],
	},
}));

const Connections = () => {
	const classes = useStyles();

	const { user } = useContext(AuthContext);
	const { apiConnections, loading } = useContext(APIConnectionsContext);
	const [confirmModalOpen, setConfirmModalOpen] = useState(false);
	const confirmationModalInitialState = {
		action: "",
		title: "",
		message: "",
		apiConnectionId: "",
		open: false,
	};
	const [confirmModalInfo, setConfirmModalInfo] = useState(
		confirmationModalInitialState
	);
	const handleOpenConfirmationModal = (action, apiConnectionId) => {
		if (action === "allow") {
			setConfirmModalInfo({
				action: action,
				title: i18n.t("allowedConnections.confirmationModal.allowTitle"),
				message: i18n.t("allowedConnections.confirmationModal.allowMessage"),
				apiConnectionId: apiConnectionId,
			});
		}

		if (action === "disallow") {
			setConfirmModalInfo({
				action: action,
				title: i18n.t("allowedConnections.confirmationModal.disallowTitle"),
				message: i18n.t("allowedConnections.confirmationModal.disallowMessage"),
				apiConnectionId: apiConnectionId,
			});
		}

		if (action === "delete") {
			setConfirmModalInfo({
				action: action,
				title: i18n.t("allowedConnections.confirmationModal.deleteTitle"),
				message: i18n.t("allowedConnections.confirmationModal.deleteMessage"),
				apiConnectionId: apiConnectionId,
			});
		}
		setConfirmModalOpen(true);
	};

	const handleSubmitConfirmationModal = async () => {
		if (confirmModalInfo.action === "disallow") {
			try {
				await api.put(`/api-connections/disallow/${confirmModalInfo.apiConnectionId}`);
				toast.success(i18n.t("allowedConnections.toasts.disallowed"));
			} catch (err) {
				toastError(err);
			}
		}

		if (confirmModalInfo.action === "allow") {
			try {
				await api.put(`/api-connections/allow/${confirmModalInfo.apiConnectionId}`);
				toast.success(i18n.t("allowedConnections.toasts.allowed"));
			} catch (err) {
				toastError(err);
			}
		}
		if (confirmModalInfo.action === "delete") {
			try {
				await api.delete(`/api-connections/${confirmModalInfo.apiConnectionId}`);
				toast.success(i18n.t("allowedConnections.toasts.deleted"));
			} catch (err) {
				toastError(err);
			}
		}

		setConfirmModalInfo(confirmationModalInitialState);
	};

	const renderActionButtons = apiConnection => {
		return (
			<>
				{apiConnection.isAllowed === true ? (
					<Button
						size="small"
						variant="contained"
						color="secondary"
						onClick={() => {
							handleOpenConfirmationModal("disallow", apiConnection.id);
						}}
					>
						{i18n.t("allowedConnections.buttons.disallow")}
					</Button>
				) : (
					<>
						<Button
							size="small"
							variant="outlined"
							color="primary"
							onClick={() => {handleOpenConfirmationModal("allow", apiConnection.id);}}
						>
							{i18n.t("allowedConnections.buttons.allow")}
						</Button>
					</>
				)}
			</>
		);
	};

	const renderStatusToolTips = apiConnection => {
		return (
			<div className={classes.customTableCell}>
				{apiConnection.isAllowed === true ? (
					<Chip label={i18n.t('allowedConnections.chips.allow')}  width={200}  color="secondary" />
				) : (
					<Chip label={i18n.t("allowedConnections.chips.disallow")}  width={200}  color="default" />
				)}
			</div>
		);
	};

	return (
		<MainContainer>
			<ConfirmationModal
				title={confirmModalInfo.title}
				open={confirmModalOpen}
				onClose={setConfirmModalOpen}
				onConfirm={handleSubmitConfirmationModal}
			>
				{confirmModalInfo.message}
			</ConfirmationModal>
			<MainHeader>
				<Title>{i18n.t("allowedConnections.title")}</Title>
			</MainHeader>
			<Paper className={classes.mainPaper} variant="outlined">
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell align="center">
								{i18n.t("allowedConnections.table.id")}
							</TableCell>
							<TableCell align="center">
								{i18n.t("allowedConnections.table.address")}
							</TableCell>
							<TableCell align="center">
								{i18n.t("allowedConnections.table.status")}
							</TableCell>
							<TableCell align="center">
								{i18n.t("allowedConnections.table.allowedBy")}
							</TableCell>
							<TableCell align="center">
								{i18n.t("allowedConnections.table.lastUpdate")}
							</TableCell>
							<Can
								role={user.profile}
								perform="allowed-connections-page:allowOrDisallowConnection"
								yes={() => (
									<TableCell align="center">
										{i18n.t("allowedConnections.table.actions")}
									</TableCell>
								)}
							/>
						</TableRow>
					</TableHead>
					<TableBody>
						{loading ? (
							<TableRowSkeleton />
						) : (
							<>
								{apiConnections?.length > 0 &&
									apiConnections.map(apiConnection => (
										<TableRow key={apiConnection.id}>
											<TableCell align="center">{apiConnection.id}</TableCell>
											<TableCell align="center">{apiConnection.ip}</TableCell>
											<TableCell align="center">
												{renderStatusToolTips(apiConnection)}
											</TableCell>
											<TableCell align="center">{apiConnection.allowedBy?.name}</TableCell>
											<TableCell align="center">{format(parseISO(apiConnection.updatedAt), "dd/MM/yy HH:mm")}</TableCell>
											<Can
												role={user.profile}
												perform="connections-page:actionButtons"
												yes={() => (
													<TableCell align="center">
														{renderActionButtons(apiConnection)}
													</TableCell>
												)}
											/>
											<Can
												role={user.profile}
												perform="connections-page:actionButtons"
												yes={() => (
													<TableCell align="center">
														<IconButton
															size="small"
															onClick={() => {handleOpenConfirmationModal("delete", apiConnection.id);}}
														>
															<DeleteOutline />
														</IconButton>
													</TableCell>
												)}
											/>
										</TableRow>
									))}
							</>
						)}
					</TableBody>
				</Table>
			</Paper>
		</MainContainer>
	);
};

export default Connections;
