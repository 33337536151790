import React, { createContext } from "react";

import useAPIConnections from "../../hooks/useAPIConnections"

const APIConnectionsContext = createContext();

const APIConnectionsProvider = ({ children }) => {
	const { loading, apiConnections } = useAPIConnections();

	return (
		<APIConnectionsContext.Provider value={{ apiConnections, loading }}>
			{children}
		</APIConnectionsContext.Provider>
	);
};

export { APIConnectionsContext, APIConnectionsProvider };
