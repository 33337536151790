import { useState, useEffect, useReducer } from "react";
import toastError from "../../errors/toastError";

import api from "../../services/api";
import { socketConnection } from "../../services/socket";

const reducer = (state, action) => {
  if (action.type === "LOAD_API_CONNECTIONS") {
    const apiConnections = action.payload;

    return [...apiConnections];
  }

  if (action.type === "UPDATE_API_CONNECTION") {
    const apiConnection = action.payload;
    const apiConnectionIndex = state.findIndex((s) => s.id === apiConnection.id);

    if (apiConnectionIndex !== -1) {
      state[apiConnectionIndex] = apiConnection;
      return [...state];
    } else {
      return [apiConnection, ...state];
    }
  }

  if (action.type === "DELETE_API_CONNECTION") {
    const apiConnectionId = action.payload;
    const apiConnectionIndex = state.findIndex((s) => s.id.toString() === apiConnectionId.toString());
    if (apiConnectionIndex !== -1) {
      state.splice(apiConnectionIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useAPIConnections = () => {
  const [apiConnections, dispatch] = useReducer(reducer, []);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const fetchSession = async () => {
      try {
        const { data } = await api.get("/api-connections");
        dispatch({ type: "LOAD_API_CONNECTIONS", payload: data });
        setLoading(false);
      } catch (err) {
        setLoading(false);
        toastError(err);
      }
    };
    fetchSession();
  }, []);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });

    socket.on(`api-connections`, (data) => {
      if (data.action === "update") {
        dispatch({ type: "UPDATE_API_CONNECTION", payload: data.apiConnection });
      }else if (data.action === "delete") {
        dispatch({ type: "DELETE_API_CONNECTION", payload: data.apiConnectionId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return { apiConnections, loading };
};

export default useAPIConnections;
